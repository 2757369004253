import "./trans";

$(document).ready(function () {
  $(window).scroll(scrollHandler);

  function scrollHandler() {
    var a = false;
    if ($(".servicelist-wrapper").visible() && !a) {
      a = true;
      increaseServiceAmount(".dp-no", 23, 100, "");
      increaseServiceAmount(".wd-no", 122, 20, "time");
      $(".dp-barred").addClass("active");
      $(".wd-barred").addClass("active");
    }
    if (a) {
      $(window).unbind("scroll", scrollHandler);
    }
  }

  faqTabHandler();

  function faqTabHandler() {
    if ($(".sub-content.faq").length !== 0) {
      var url = window.location.hash;
      var idx = url.indexOf("#");
      var hash = idx != -1 ? url.substring(idx + 1) : "";

      $(".itms").click(function (e) {
        var cur = $(this).index() + 1;
        $(this).addClass("active");
        $(".itms").not($(this)).removeClass("active");
        $(".faqcontent #content" + cur + ".col2").show();
        $(".faqcontent .col2")
          .not($(".faqcontent #content" + cur + ".col2"))
          .hide();
      });
    }
  }

  initVideo();

  function initVideo() {
    if ($("#popup-video").length !== 0) {
      var promoVideo = videojs("promo-video");
      $(".popup .close").click(function () {
        promoVideo.pause();
        promoVideo.currentTime(0);
      });
    }
  }
  function increaseServiceAmount(b, a, f, c) {
    var e = 0;
    var d = setInterval(function () {
      e += 1;
      if (c === "time") {
        var g = moment.utc(e * 1000).format("m'ss");
        $(b).text(g);
      } else {
        $(b).text(e);
      }
      if (e === a) {
        clearInterval(d);
      }
    }, f);
  }

  // $(".menu-links.lottery").hover(
  //   function(b) {
  //     var c = $(this).parents('.navigation').find(".lottery-dropdown-wrapper");
  //     c.show();
  //   },
  //   function(b) {
  //     var c = $(this).parents('.navigation').find(".lottery-dropdown-wrapper");
  //     c.hide();
  //   }
  // );
  $(".menu-links.lottery").hover(
    function (b) {
      var panel = $(this).find(".lottery-dropdown-wrapper");
      var arrow = $(this).find(".arrow");
      var panelOffset = ($(window).width() - panel.width()) / 2 - $(this).offset().left;
      var aroowOffset = $(this).offset().left - ($(window).width() - panel.width()) / 2 + $(this).width() / 2;
      panel.css("left", panelOffset + "px");
      arrow.css("left", aroowOffset + "px");
      panel.show();
    },
    function (b) {
      var c = $(this).find(".lottery-dropdown-wrapper");
      c.hide();
    }
  );

  // Promotion
  $(".promobanner").click(function () {
    var b = $(this).parent().find(".contentbody");
    var a = $(b).css("display");
    if (a == "block") {
      $(b).fadeOut(500);
    } else {
      $(b).fadeIn(500);
    }
  });
  $(".contentbody").each(function (a, b) {
    if (a == 0) {
      $(this).css("display", "block");
    } else {
      $(this).css("display", "none");
    }
  });

  // Sidebar
  $(".wechat").hover(
    function () {
      $(".wechatqr").stop(true, true).fadeIn(500);
    },
    function () {
      $(".wechatqr").stop(true, true).fadeOut(200);
    }
  );
});

// Date timer
$(function () {
  setInterval(updateTime, 1000);
  function updateTime() {
    var date = new Date().toLocaleString("en-US", { hour12: false }).split(" ");
    var time = date[1];
    var mdy = date[0];
    mdy = mdy.split("/");
    var month = parseInt(mdy[0]);
    var day = parseInt(mdy[1]);
    var year = parseInt(mdy[2]);
    var formattedDate = year + "-" + month + "-" + day + " " + time;

    $(".datetime .showTime").text(formattedDate);
  }
  updateTime();
});

// Banner
$(function () {
  function initBannerCarousel() {
    const bannerList = window.banner;
    const indexContainer = $("#index-banner.swiper-wrapper");
    const promoContainer = $("#promo-banner.swiper-wrapper");
    const isLoggedIn = $(".before-login.hide").length !== 0;

    bannerList &&
      bannerList.length !== 0 &&
      bannerList.forEach(function (item) {
        const { imageUrl, redirectUrl, redirectGroup, redirectPage, bannerType } = item;

        if (imageUrl) {
          /**
           * Redirect Group Option
           * 0 - Promo
           * 1 - Join Us
           * 2 - Lottery
           * 3 - Product
           * 4 - Others inner page (Loyalty, Customer Service)
           * 5 - 自定义
           */
          const banner = $(`
            <div class="swiper-slide" data-swiper-autoplay="5000">
                <img ${redirectUrl ? `data-redirect=${redirectUrl}` : ""} class="img" src="${imageUrl}"/>
                <div class="img-bg" style="background-image: url(${imageUrl})"></div>
            </div>
          `);

          banner.click(() =>
            window.initBannerCarouselRedirection({
              redirectUrl,
              redirectGroup,
              redirectPage,
              isLoggedIn,
            })
          );

          /**
           * index append 首页type banner, promo append 优惠活动type banner
           * 轮播图类型 bannerType
           * 0 - 首页
           * 1 - 优惠活动
           * 2 - 其他
           */
          if (bannerType === 0) {
            indexContainer.append(banner);
          }
          if (bannerType === 1) {
            promoContainer.append(banner);
          }
        }
      });

    if (bannerList && bannerList.length) {
      var mySwiper = new Swiper(".swiper-container.banner", {
        speed: 400,
        autoplay: {
          delay: 5000,
        },
        loop: true,
        // If we need pagination
        pagination: {
          el: ".swiper-pagination.banner",
        },
      });
    }
  }

  if (Pace) {
    Pace.on("done", initBannerCarousel);
  } else {
    $(window).load(initBannerCarousel);
  }
});

function gameWrapperDetector() {
  var menuOffset = $(".main-menu .menu-links.lottery").offset().left;
  var windowWidth = $(window).width();
  var menuWidth = $(".main-menu .menu-links.lottery").outerWidth();

  var offsetRight = windowWidth - (menuOffset + menuWidth);
  var lotteryWrapperWidth = $(".lottery-dropdown-wrapper").innerWidth();

  if (offsetRight < lotteryWrapperWidth / 2) {
    $(".lottery-dropdown-wrapper").addClass("right");
  }
}

//Load trans
$(function () {
  // var transactionsContainer = $("#transactionsContainer");
  // transactionsContainer.children().remove();
  // if (!!transactionsContainer.length) {
  //   api.getTrans(function(response) {
  //     response.data.result.forEach(function(info, i) {
  //       // console.log(info);
  //       transactionsContainer.append(
  //         $(
  //           `<div class="info${i == 0 ? " active" : ""}">
  //             恭喜会员 ${info.username} 提款 ${-info.amount} 元成功，请及时查看银行卡账目。
  //           </div>`
  //         )
  //       );
  //     });
  //     window.transInit();
  //   });
  // }

  var transactionsContainer = $("#transactionsContainer");
  transactionsContainer.children().remove();
  if (!!transactionsContainer.length) {
    api.getTrans(function (response) {
      if (response.data.result.length === 1) {
        var amount = response.data.result[0].amount;
        var username = response.data.result[0].username;
        var content =
          `<div class="info active">
              恭喜会员 ${username} 提款 ${-amount} 元成功，请及时查看银行卡账目。
            </div>` +
          `<div class="info">
              恭喜会员 ${username} 提款 ${-amount} 元成功，请及时查看银行卡账目。
            </div>`;
        transactionsContainer.append(content);
      } else {
        response.data.result.forEach(function (info, i) {
          console.log(info);
          transactionsContainer.append(
            $(
              `<div class="info${i == 0 ? " active" : ""}">
              恭喜会员 ${info.username} 提款 ${-info.amount} 元成功，请及时查看银行卡账目。
            </div>`
            )
          );
        });
      }
    });
  }
});

// Load announcement
// $(function() {
//   var announcementContainer = $("#announcementContainer");
//   announcementContainer.children().remove();
//   if (!!announcementContainer.length) {
//     api.getNotice(function(response) {
//       response.data.result.forEach(function(info, i) {
//         announcementContainer.append($(`<div class="info${i == 0 ? " active" : ""}">${info.text}</div>`));
//       });
//     });
//   }
// });

// announcement slide up
$(function () {
  if ($(".info-wrapper").length) {
    $(".info-wrapper").each(function (i, elem) {
      var intervalId = null;
      intervalId = window.setInterval(nextInfo, 2000);
      $(elem).on("mouseenter", function () {
        window.clearInterval(intervalId);
      });
      $(elem).on("mouseleave", function () {
        intervalId = window.setInterval(nextInfo, 2000);
      });

      function nextInfo() {
        var currentActive = $(elem).find(".info.active");
        currentActive.removeClass("active");
        if (currentActive.next().length) {
          currentActive.next().addClass("active");
        } else {
          $(elem).find(".info").eq(0).addClass("active");
        }
      }
    });
  }
});

// promotion

$(function () {
  if (window.promo) {
    renderPromo(window.promo);
  } else {
    Object.defineProperty(window, "promo", {
      set: function (data) {
        renderPromo(data);
      },
    });
  }

  $(".promo-menu-links").click(function () {
    var dataType = $(this).data("type");
    $(this).siblings().removeClass("active");
    $(this).addClass("active");

    if (dataType === -1) {
      $(".promo-box").show();
    } else {
      $('.promo-box[data-type="' + dataType + '"]').show();
      $('.promo-box:not([data-type="' + dataType + '"])').hide();
    }
  });

  function registerPromo(promoId) {
    api.registerPromo(promoId);
  }

  function renderPromo(promoData) {
    if (Array.isArray(promoData)) {
      const promoWrapper = $("#promotionWrapper");
      let promohash = location.hash !== "" ? location.hash.split("#")[1] : "";

      promoData.forEach(function (value, index) {
        var promoItem = $(`<div class="promo-box" data-type="${value.type}"></div>`);
        var promoId = value.promoId;
        var requestToJoin = value.requestToJoin;
        var firstDepositPromoJoinMsg = value.firstDepositPromoJoinMsg;

        promoItem.append(
          $(`
            ${
              value.imagePath
                ? `
                  <div class="sub-content-item-header" data-header="${promoId}" style="font-size:0;">
                    <img class="banner" src="${value.imagePath}" alt="${value.title}" title="${value.title}"/>
                  </div>
                  `
                : `
                  <div class="sub-content-item-header">
                    <h3>${value.title}</h3>
                  </div>
                `
            }
            <div class="sub-content-item-content" ${
              promohash.length > 0 && promohash === promoId ? `style="display:block"` : ""
            }>
              ${value.content}
              ${
                requestToJoin
                  ? `
                     <div
                       class="sub-content-item-footer"
                       style="display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; height: 50px;"
                     >
                       ${
                         firstDepositPromoJoinMsg
                           ? `
                             <div class="sub-content-item-footer--info" style="color: #555;">
                               ${firstDepositPromoJoinMsg}
                             </div>
                           `
                           : `
                             <button
                               class="button sub-content-item-footer--register-promo"
                               data-id="${promoId}"
                               style="cursor:pointer;color:#fff;outline: 0;border:0;width: 100px;"
                             >
                               申请参与
                             </button>
                           `
                       }
                     </div>
                   `
                  : ``
              }
            </div>
            `)
        );
        promoWrapper.append(promoItem);
      });

      $(".promo-box .sub-content-item-footer--register-promo").click(function (e) {
        e.preventDefault();
        var promoId = $(this).data("id");
        registerPromo(promoId);
      });

      $(".promo-box .sub-content-item-header").click(function (e) {
        e.preventDefault();
        $(this).siblings(".sub-content-item-content").slideToggle();
      });

      if (promohash.length > 0) {
        const scrollTop = $(`.sub-content-item-header[data-header='${promohash}']`).offset().top - 50;
        $("html, body").animate({ scrollTop: scrollTop }, 1000);
      }
    }
  }
});
